'use client'
import { useTranslation } from 'next-i18next'
import getConfig from '@/config'
import React, { useEffect, useState } from 'react'

import {
  calculateScoreAndPassStatus,
  LearningModuleRevisionType,
  LearningSessionScore,
  SeriesURLParts,
} from '../../../../graphql/learning'
import { useModulesInSeriesPassed } from '../../../../graphql/learning/hooks'

import { isNativeAppBrowser } from '../../../../lib/client-info'
import { useUser } from '../../../../modules/auth/hooks'
import Loading from '../../../../ui/loading'
import { postNotification } from '../../../../modules/notify/actions'
import { useDispatch } from '../../../../react-redux'
import callAPI from '../../../../modules/api/call'
import useForm from '../../../Forms/useForm'

const config = getConfig().publicRuntimeConfig

export const CertificateLinkForSeries: React.FC<{
  series: SeriesURLParts
  button?: boolean
}> = ({ series, button }) => {
  if (series.accreditation_when === 'series') {
    const { hasPassedAll } = useModulesInSeriesPassed(series.id)
    if (hasPassedAll) {
      return <CertificateLink series={series} button={button} module_id="_" />
    }
  }
  return null
}

export const CertificateLinkForRevisionSession: React.FC<{
  series: SeriesURLParts
  session: LearningSessionScore
  revision: LearningModuleRevisionType
  button?: boolean
}> = ({ series, session, revision, button }) => {
  if (series.accreditation_when === 'module') {
    if (
      !revision.mcq_settings_enabled ||
      revision.mcq_settings_pass_mark_type === 'none' ||
      session.status === 'no_pass_mark' ||
      calculateScoreAndPassStatus(session, revision)?.isPass
    ) {
      return (
        <CertificateLink
          series={series}
          button={button}
          module_id={revision.module_id}
        />
      )
    }
  }
  return null
}

const useCheckFeedbackForm = (
  revision: LearningModuleRevisionType,
  currentUser: any
) => {
  const hasCustomFeedbackForm = !!(
    revision.settings?.custom_feedback_form_active && revision?.feedback_form_id
  )

  const { form: feedbackForm, form_responses } = hasCustomFeedbackForm
    ? useForm(revision?.feedback_form_id)
    : { form: null, form_responses: null }

  const [alreadySubmitted, setAlreadySubmitted] = useState(false)
  const allowMulti = feedbackForm?.allowMultipleSubmissions || false
  const hasRequiredFields = feedbackForm?.fields.some(field => field?.required)

  useEffect(() => {
    setAlreadySubmitted(form_responses?.length > 0 && !allowMulti)
  }, [form_responses, allowMulti])

  return {
    feedbackForm,
    alreadySubmitted,
    hasCustomFeedbackForm,
    hasRequiredFields,
  }
}

const Link: React.FC<{
  series: SeriesURLParts
  session: LearningSessionScore
  revision: LearningModuleRevisionType
  button?: boolean
}> = ({ series, session, revision, button }) => {
  const currentUser = useUser()
  const { hasRequiredFields, alreadySubmitted, hasCustomFeedbackForm } =
    useCheckFeedbackForm(revision, currentUser)
  const hideButton =
    hasCustomFeedbackForm && !alreadySubmitted && hasRequiredFields

  return hideButton ? null : series.accreditation_when === 'series' ? (
    <CertificateLinkForSeries series={series} button={button} />
  ) : series.accreditation_when === 'module' ? (
    <CertificateLinkForRevisionSession
      series={series}
      button={button}
      session={session}
      revision={revision}
    />
  ) : null
}

const CertificateLink: React.FC<{
  series: SeriesURLParts
  module_id: string
  button?: boolean
}> = ({ series, module_id, button }) => {
  const { t } = useTranslation('learning')
  const dispatch = useDispatch()

  const certificateUrl = `${config.mainSiteUrl}/learning/groups/${series.library_id}/${series.id}/${module_id}/certificate?pdf=1`
  const pdfUrl = `${config.pdfUrl}?src=${encodeURIComponent(
    certificateUrl
  )}&waitForFunction=${encodeURIComponent('window.readyToPrint === true')}`
  const sendByEmailUrl = `${config.mainSiteUrl}/api/learning/groups/${series.library_id}/series/${series.id}/module/${module_id}/send-to-email`

  const [sending, setSending] = useState(false)

  const sendByEmail = async () => {
    setSending(true)

    callAPI({
      url: sendByEmailUrl,
      method: 'GET',
    })
      .then(r => {
        dispatch(
          postNotification({
            kind: 'info',
            message:
              'Email with a link to the certificate has been sent to your email.',
            closeBtn: false,
          })
        )
        setSending(false)
      })
      .catch(e => {
        dispatch(
          postNotification({
            kind: 'error',
            message: 'Something went wrong.',
            closeBtn: false,
          })
        )
        setSending(false)
      })
  }

  return isNativeAppBrowser() ? (
    <>
      {sending ? (
        <Loading />
      ) : (
        <a
          className={button ? 'btn' : undefined}
          style={button ? undefined : { textDecoration: 'underline' }}
          onClick={() => sendByEmail()}
        >
          {t('learning.send_cert_by_email')}
        </a>
      )}
    </>
  ) : (
    <a
      className={
        button ? 'btn btn--print-certificate' : 'link--print-certificate'
      }
      style={button ? undefined : { textDecoration: 'underline' }}
      href={pdfUrl}
      target={'_blank'}
      rel="noreferrer noopener"
    >
      {t('learning.download_your_certificate')}
    </a>
  )
}

export default Link
