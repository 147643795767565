import { useAuthenticatedGetRequest } from '../../modules/api/hooks'
import { FormType } from './types'

interface FormTypeQueryResult {
  form?: FormType
  form_responses?: any[]
  error: Error | undefined
  loading: boolean
}

const useForm = (formID: string): FormTypeQueryResult => {
  if (!formID) return

  const { loading, error, data } = useAuthenticatedGetRequest(
    '/api/cms/forms/' + formID
  )

  if (data && data['status'] === 'ok' && data['data']['form']) {
    const responseForm = data['data']['form'] as any
    const form: FormType = {
      id: responseForm.id,
      creatorID: responseForm.creator_id,
      name: responseForm.name,
      description: responseForm.description,
      fields: JSON.parse(responseForm.fields),
      isFeedbackForm: responseForm.is_feedback_form,
      allowMultipleSubmissions: responseForm.allow_multiple_submissions,
      groupId: responseForm.group_id,
    }

    return {
      loading,
      error,
      form,
      form_responses: data['data']['form_responses'],
    }
  }

  return { loading, error }
}

export default useForm
